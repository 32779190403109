<template>
    <b-skeleton-img
        v-if="busy"
        height="300px"
    />
    <apexchart
        v-else
        class="apex-charts"
        height="300"
        type="line"
        :series="series"
        :options="options"
    />
</template>

<script>
  export default {
    name: "LineChart",
    props: {
        seriesProp: {
            required: true,
            type: Array,
        },
        categoriesSeriesProp: {
            required: true,
            type: Array,
        },
        colorsProp: {
            required: false,
            type: Array,
            default: () => {
                return ['#2E7FEB', '#F3D63F', '#64BB77', '#FD4084', '#F4AC57', '#C51FE0', '#404249']
            }
        },
        busy: {
            required: false,
            type: Boolean,
        },
        chartProps: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    data() {
        return {}
    },
    computed: {
        series() {
            return this.seriesProp
        },
        options() {
            return {
                chart: {
                    height: 300,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    },
                },
                dataLabels: {
                    enabled: true,
                    background: {
                        borderRadius: 5,
                        borderColor: 'transparent',
                        opacity: 1,
                    }
                },
                stroke: {
                    curve: 'straight'
                },
                grid: {
                    row: {
                        colors: ['#f9f9f9', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: this.categoriesSeriesProp
                },
                colors: this.colorsProp,
                ...this.chartProps,
            }
        },
    }

  }
</script>
