import BaseService from '@src/services/BaseService'

class ProductCategoriesService extends BaseService {
    constructor() {
        super('product-categories')
    }

    fetchByName(name) {
        return this.fetchAll({ search: `name:${name}` })
    }

    fetchChildren(parentId) {
        return this.fetchAll({ search: `parent_id:${parentId}` })
    }
}

export default new ProductCategoriesService()
