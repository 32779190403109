<template>
    <div>
        <b-row>
            <b-col md="3">
                <indicator-card
                    ref="chartAveragePrice"
                    :title="i18nAveragePrice"
                    :busy="loadingResume"
                    :value="averagePrice"
                    type="currency"
                />
            </b-col>
            <b-col md="3">
                <indicator-card
                    ref="chartMinPrice"
                    :title="i18nMinPrice"
                    :busy="loadingResume"
                    :value="minimumPrice"
                    type="currency"
                />
            </b-col>
            <b-col md="3">
                <indicator-card
                    ref="chartMaxPrice"
                    :title="i18nMaxPrice"
                    :busy="loadingResume"
                    :value="maximumPrice"
                    type="currency"
                />
            </b-col>
            <b-col md="3">
                <indicator-card
                    ref="chartModePrice"
                    :title="i18nModePrice"
                    :busy="loadingResume"
                    :value="modePrice"
                    type="currency"
                />
            </b-col>
        </b-row>
        <b-row>
            <b-col md="4">
                <div class="card card-pdv">
                    <PageHeader :title="i18nAveragePricePerWeek" />
                    <div class="card-body">
                        <line-chart
                            :series-prop="seriesAverangePrice"
                            :categories-series-prop="categoriesAverangePrice"
                            :busy="loadingAverangePrice"
                        />
                    </div>
                </div>
            </b-col>
            <b-col md="8">
                <div class="card card-pdv">
                    <PageHeader :title="i18nAveragePriceCompared" />
                    <div class="card-body">
                        <line-chart
                            :series-prop="seriesComparativePrice"
                            :categories-series-prop="categoriesComparativePrice"
                            :busy="loadingComparativePrice"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6">
                <div class="card card-pdv">
                    <PageHeader :title="i18nMaxPriceVersusMinPrice" />
                    <div class="card-body">
                        <line-chart
                            :series-prop="seriesMaxMinPrice"
                            :categories-series-prop="categoriesMaxMinPrice"
                            :colors-prop="['#64BB77', '#FD4084']"
                            :busy="loadingMaxMinPrice"
                        />
                    </div>
                </div>
            </b-col>
            <b-col md="6">
                <div class="card card-pdv">
                    <PageHeader :title="i18nAverangePriceCategory" />
                    <div class="card-body">
                        <line-chart
                            :series-prop="seriesAverangePriceCategory"
                            :categories-series-prop="categoriesAverangePriceCategory"
                            :colors-prop="['#C51FE0']"
                            :busy="loadingAverangePriceCategory"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <div class="card card-pdv">
                    <PageHeader :title="i18nMediumProductMediumCategory" />
                    <div class="card-body">
                        <line-chart
                            :series-prop="seriesMediumProductMediumCategory"
                            :categories-series-prop="categoriesMediumProductMediumCategory"
                            :busy="loadingMediumProductMediumCategory"
                            :chart-props="mediumProductMediumCategoryChartProps"
                        />
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="12">
                <average-price-compare ref="averagePriceCompare" />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import PriceService from '@src/services/dashboards/widgets/PriceService.js'
import ProductCategoriesService from '@src/services/ProductCategoriesService.js'

import PageHeader from '@/src/components/PageHeader.vue'
import IndicatorCard from '@/src/components/dashboards/IndicatorCard.vue'
import LineChart from '@/src/components/charts/LineChart.vue'
import averagePriceCompare from '@/src/router/views/dashboards/price/averagePriceCompare.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters.vue'
import { map } from 'lodash'
import cloneObject from '@/src/utils/clone-object'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
        IndicatorCard,
        LineChart,
        averagePriceCompare,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatters,
    ],
    props: {
        categoryId: {
            type: Number,
            required: false,
            default: 0,
        },
        product: {
            type: Object,
            required: false,
            default: null,
        },
        competitor: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            i18nKey,
            perPage: 10,
            additionalParameters: {},
            loadingResume: false,
            loadingAverangePrice: false,
            loadingComparativePrice: false,
            loadingMaxMinPrice: false,
            loadingAverangePriceCategory: false,
            loadingMediumProductMediumCategory: false,
            averagePrice: 0,
            minimumPrice: 0,
            maximumPrice: 0,
            modePrice: 0,
            categoryName: '',
            seriesAverangePrice: [],
            seriesComparativePrice: [],
            seriesMaxMinPrice: [],
            seriesAverangePriceCategory: [],
            seriesMediumProductMediumCategory: [],
            categoriesAverangePrice: [],
            categoriesComparativePrice: [],
            categoriesMaxMinPrice: [],
            categoriesAverangePriceCategory: [],
            categoriesMediumProductMediumCategory: [],
            mediumProductMediumCategoryChartProps: {
                dataLabels: {
                    enabled: true,
                    formatter: function (value, opts) {
                        if (!value) {
                            return '0%'
                        }

                        return `${Math.round(value)}%`
                    },
                },
                tooltip: {
                    y: {
                        formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                            if (!value) {
                                return '0%'
                            }

                            return `${Math.round(value)}%`
                        }
                    }
                },
            },
            sortOrder: [
                {
                    field: 'product|product.name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'product.name',
                    title: this.getI18n('PRODUCTS', 'TITLES.product'),
                    sortField: 'product|product.name',
                },
                {
                    name: 'first_week',
                    title: this.getI18n(i18nKey, 'TYPES.first_week'),
                    sortField: 'first_week',
                },
                {
                    name: 'second_week',
                    title: this.getI18n(i18nKey, 'TYPES.second_week'),
                    sortField: 'second_week',
                },
                {
                    name: 'third_week',
                    title: this.getI18n(i18nKey, 'TYPES.third_week'),
                    sortField: 'third_week',
                },
                {
                    name: 'fourth_week',
                    title: this.getI18n(i18nKey, 'TYPES.fourth_week'),
                    sortField: 'fourth_week',
                },
                {
                    name: 'fifth_week',
                    title: this.getI18n(i18nKey, 'TYPES.fifth_week'),
                    sortField: 'fifth_week',
                },
            ]
        }
    },
    validations: {},
    computed: {
        i18nAveragePrice() {
            return this.getI18n(i18nKey, 'TITLES.average_price')
        },
        i18nCategory() {
            return this.getI18n('PRODUCTS', 'FIELDS.category')
        },
        i18nProduct() {
            return this.getI18n('PRODUCTS', 'TITLES.product')
        },
        i18nMinPrice() {
            return this.getI18n('PRODUCTS', 'FIELDS.min_price')
        },
        i18nMaxPrice() {
            return this.getI18n('PRODUCTS', 'FIELDS.max_price')
        },
        i18nModePrice() {
            return this.getI18n(i18nKey, 'TITLES.mode_price')
        },
        i18nAveragePricePerDay() {
            return this.getI18n(i18nKey, 'TITLES.average_price_per_day')
        },
        i18nAveragePricePerWeek() {
            return this.getI18n(i18nKey, 'TITLES.average_price_per_week')
        },
        i18nAveragePriceCompared() {
            if (this.competitor) {
                return `${this.i18nAveragePricePerDay} - ${this.getI18n('PRODUCTS', 'TITLES.product')} x ${this.getI18n('PRODUCTS', 'FIELDS.competitor')}`
            }
            return this.i18nAveragePricePerDay
        },
        i18nMaxPriceVersusMinPrice() {
            return `${this.i18nMaxPrice} x ${this.i18nMinPrice}`
        },
        i18nMediumProductMediumCategory() {
            return `${this.i18nAveragePrice} ${this.i18nProduct} x ${this.i18nAveragePrice} ${this.i18nCategory}`
        },
        i18nAverangePriceCategory() {
            return `${this.i18nAveragePrice} (${this.categoryName ? this.categoryName : this.i18nCategory})`
        },
        i18nPriceComparison() {
            return this.getI18n(i18nKey, 'TITLES.price_comparison')
        },
    },
    methods: {
        refresh(parameters) {
            this.additionalParameters = parameters
            this.refreshResume()
            this.refreshAveragePrice()
            this.refreshComparativePrice()
            this.refreshMaxPriceVersusMinPrice()
            this.refreshAveragePriceCategory()
            this.refreshMediumProductMediumCategory()
            this.$refs.averagePriceCompare.refresh(this.additionalParameters)
        },
        refreshResume() {
            this.loadingResume = true
            this.averagePrice = 0
            this.minimumPrice = 0
            this.maximumPrice = 0
            this.modePrice = 0

            PriceService.resume(this.additionalParameters)
                .then((response) => {
                    this.averagePrice = response.data.data.avg_number ?? 0
                    this.minimumPrice = response.data.data.min_number ?? 0
                    this.maximumPrice = response.data.data.max_number ?? 0
                    this.modePrice = response.data.data.mode_number ?? 0

                    this.loadingResume = false
                })
                .catch((err) => {
                    this.loadingResume = false
                    return err
                })
        },
        refreshAveragePrice() {
            this.loadingAverangePrice = true
            this.seriesAverangePrice = []
            this.categoriesAverangePrice = []

            PriceService.weekMedium(this.additionalParameters)
                .then((response) => {
                    const data = response.data.data
                    if (data.length > 0) {
                        this.seriesAverangePrice = [
                            {
                                name: data[0].product_name,
                                data: map(data, 'avg_number'),
                            },
                        ]

                        data.forEach((item) => {
                            if (item.week === 1) {
                                this.categoriesAverangePrice.push(this.getI18n(i18nKey, 'TYPES.first_week'))
                            }
                            else if (item.week === 2) {
                                this.categoriesAverangePrice.push(this.getI18n(i18nKey, 'TYPES.second_week'))
                            }
                            else if (item.week === 3) {
                                this.categoriesAverangePrice.push(this.getI18n(i18nKey, 'TYPES.third_week'))
                            }
                            else if (item.week === 4) {
                                this.categoriesAverangePrice.push(this.getI18n(i18nKey, 'TYPES.fourth_week'))
                            }
                            else if (item.week === 5) {
                                this.categoriesAverangePrice.push(this.getI18n(i18nKey, 'TYPES.fifth_week'))
                            }
                        })
                    }

                    this.loadingAverangePrice = false
                })
                .catch((err) => {
                    this.loadingAverangePrice = false
                    return err
                })
        },
        refreshComparativePrice() {
            this.loadingComparativePrice = true
            this.seriesComparativePrice = []
            this.categoriesComparativePrice = []

            PriceService.mediumCompare(this.additionalParameters)
                .then((response) => {
                    const data = response.data.data

                    if (data.length > 0) {
                        data.forEach(product => {
                            product.avg_number = Number(product.avg_number).toFixed(2)
                        })

                        if (this.competitor && this.competitor.id > 0) {
                            data.filter(item => item.id === this.product.id).forEach(product => {
                                if (data.find(competitor => competitor.id === this.competitor.id && competitor.date === product.date)) {
                                    product.avg_number = Number(product.avg_number).toFixed(2)
                                } else {
                                    data.push({
                                        id: this.competitor.id,
                                        product_name: this.competitor.name,
                                        avg_number: 0,
                                        date: product.date,
                                    })
                                }
                            });

                            data.filter(item => item.id === this.competitor.id).forEach(competitor => {
                                if (data.find(product => product.id === this.product.id && product.date === competitor.date)) {
                                    competitor.avg_number = Number(competitor.avg_number).toFixed(2)
                                } else {
                                    data.push({
                                        id: this.product.id,
                                        product_name: this.product.name,
                                        avg_number: 0,
                                        date: competitor.date,
                                    })
                                }
                            });

                            this.seriesComparativePrice = [
                                {
                                    name: this.product.name,
                                    data: map(data.filter(item => item.id === this.product.id), 'avg_number'),
                                },
                                {
                                    name: this.competitor.name,
                                    data: map(data.filter(item => item.id === this.competitor.id), 'avg_number'),
                                },
                            ]
                        }
                        else {
                            this.seriesComparativePrice = [
                                {
                                    name: this.product.name,
                                    data: map(data.filter(item => item.id === this.product.id), 'avg_number'),
                                },
                            ]
                        }

                        data.forEach(item => {
                            if (!this.categoriesComparativePrice.find(date => date === this.formatDate(item.date))) {
                                this.categoriesComparativePrice.push(this.formatDate(item.date))
                            }
                        })
                    }

                    this.loadingComparativePrice = false
                })
                .catch((err) => {
                    this.loadingComparativePrice = false
                    return err
                })
        },
        refreshMaxPriceVersusMinPrice() {
            this.loadingMaxMinPrice = true
            this.seriesMaxMinPrice = []
            this.categoriesMaxMinPrice = []

            PriceService.minMax(this.additionalParameters)
                .then((response) => {
                    const data = response.data.data
                    if (data.length > 0) {
                        this.seriesMaxMinPrice = [
                            {
                                name: this.getI18n('PRODUCTS', 'FIELDS.max_price'),
                                data: map(data, 'max_number'),
                            },
                            {
                                name: this.getI18n('PRODUCTS', 'FIELDS.min_price'),
                                data: map(data, 'min_number'),
                            },
                        ]

                        data.forEach(item => {
                            this.categoriesMaxMinPrice.push(this.formatDate(item.date))
                        })
                    }

                    this.loadingMaxMinPrice = false
                })
                .catch((err) => {
                    this.loadingMaxMinPrice = false
                    return err
                })
        },
        async refreshAveragePriceCategory() {
            this.loadingAverangePriceCategory = true
            this.seriesAverangePriceCategory = []
            this.categoriesAverangePriceCategory = []
            this.categoryName = ''

            if (this.categoryId > 0) {
                const response = await ProductCategoriesService.fetchById(this.categoryId, {
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                })

                if (response.status.toString().charAt(0) === '2') {
                    this.categoryName = response.data.data.name
                }
            }

            const mediumCategoryParameters = cloneObject(this.additionalParameters)
            delete mediumCategoryParameters.product_id

            PriceService.mediumCategory(mediumCategoryParameters)
                .then((response) => {
                    const data = response.data.data
                    if (data.length > 0) {
                        this.seriesAverangePriceCategory = [
                            {
                                name: this.categoryName ?? this.getI18n(i18nKey, 'TITLES.average_price'),
                                data: map(data, 'avg_number'),
                            },
                        ]

                        data.forEach(item => {
                            this.categoriesAverangePriceCategory.push(this.formatDate(item.date))
                        })
                    }

                    this.loadingAverangePriceCategory = false
                })
                .catch((err) => {
                    this.loadingAverangePriceCategory = false
                    return err
                })
        },
        async refreshMediumProductMediumCategory() {
            this.loadingMediumProductMediumCategory = true
            this.seriesMediumProductMediumCategory = []
            this.categoriesMediumProductMediumCategory = []

            PriceService.mediumProductCompareMediumCategory(this.additionalParameters)
                .then((response) => {
                    const data = response.data
                    if (data.length > 0) {

                        this.seriesMediumProductMediumCategory = [
                            {
                                name: this.getI18n(i18nKey, 'TITLES.price_comparison'),
                                data: map(data, 'perc_compare'),
                            },
                        ]

                        data.forEach(item => {
                            this.categoriesMediumProductMediumCategory.push(this.formatDate(item.date))
                        })
                    }

                    this.loadingMediumProductMediumCategory = false
                })
                .catch((err) => {
                    this.loadingMediumProductMediumCategory = false
                    return err
                })
        },
    },
}
</script>
