<template>
    <div>
        <b-row>
            <b-col md="12">
                <div class="card card-pdv">
                    <PageHeader :title="i18nOutPrice" />
                    <div class="card-body">
                        <api-mode-vuetable
                            ref="vuetable"
                            :fetch-data="fetch"
                            :fields="fields"
                            :per-page="perPage"
                            :additional-parameters="additionalParameters"
                            :sort-order="sortOrder">
                            <template slot="variation" slot-scope="props">
                                <div v-if="props.rowData.avg_number > props.rowData.max_price">
                                    <i class="fe-arrow-up-circle icon-red mr-1" />
                                    {{ i18nMaximumExceeded }}
                                </div>
                                <div v-else-if="props.rowData.avg_number < props.rowData.min_price">
                                    <i class="fe-arrow-down-circle icon-orange mr-1" />
                                    {{ i18nMinimumExceeded }}
                                </div>
                                <div v-else>
                                    <i class="fe-circle icon-green mr-1" />
                                    {{ i18nNormal }}
                                </div>
                            </template>
                            <template slot="actions" slot-scope="props">
                                <div class="table-button-container">
                                    <more-table-button @onClick="openModalDetail(props.rowData)" />
                                </div>
                            </template>
                        </api-mode-vuetable>
                    </div>
                </div>
            </b-col>
        </b-row>
        <out-price-detail-modal ref="outPriceDetailModal" />
    </div>
</template>

<script>
import PriceService from '@src/services/dashboards/widgets/PriceService.js'

import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import outPriceDetailModal from '@/src/router/views/dashboards/price/outPriceDetailModal.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        ApiModeVuetable,
        PageHeader,
        MoreTableButton,
        outPriceDetailModal,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatter,
    ],
    data() {
        return {
            i18nKey,
            perPage: 10,
            additionalParameters: {},
            sortOrder: [
                {
                    field: 'product_name',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'pos_name',
                    title: this.getI18n('POS', 'TITLES.pos'),
                    sortField: 'pos_name',
                },
                {
                    name: 'chain_name',
                    title: this.getI18n('POS', 'TITLES.chain'),
                    sortField: 'chain_name',
                },
                {
                    name: 'product_name',
                    title: this.getI18n('PRODUCTS', 'TITLES.product'),
                    sortField: 'product_name',
                },
                {
                    name: 'max_price',
                    title: this.getI18n(i18nKey, 'TITLES.maximum_tolerance'),
                    sortField: 'max_price',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'min_price',
                    title: this.getI18n(i18nKey, 'TITLES.minimum_tolerance'),
                    sortField: 'min_price',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'avg_number',
                    title: this.getI18n(i18nKey, 'TITLES.average_price'),
                    sortField: 'avg_number',
                    formatter: this.getI18nCurrency,
                },
                {
                    name: 'variation',
                    title: this.getI18n(i18nKey, 'TITLES.variation'),
                    sortField: 'avg_number',
                },
                {
                    name: 'actions',
                    title: '',
                },
            ]
        }
    },
    validations: {},
    computed: {
        i18nOutPrice() {
            return this.getI18n(i18nKey, 'TITLES.out_price')
        },
        i18nMaximumExceeded() {
            return this.getI18n(i18nKey, 'TITLES.maximum_exceeded')
        },
        i18nMinimumExceeded() {
            return this.getI18n(i18nKey, 'TITLES.minimum_exceeded')
        },
        i18nNormal() {
            return this.getI18n(i18nKey, 'TITLES.normal')
        },
    },
    methods: {
        refresh(parameters) {
            this.additionalParameters = parameters
            this.refreshTable()
        },
        fetch(url, params) {
            return PriceService.fetchVuetable('out', params)
        },
        openModalDetail(data) {
            this.$refs.outPriceDetailModal.showModal(data, this.additionalParameters)
        },
        refreshTable() {
            this.$nextTick(() => this.$refs.vuetable.refresh())
        },
    },
}
</script>
