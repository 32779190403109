<template>
    <div>
        <div
            v-infinite-scroll="refresh"
            infinite-scroll-disabled="disableInfiniteScroll"
            infinite-scroll-distance="limit"
            infinite-scroll-throttle-delay="300"
            >
            <div v-if="dataPagination.length > 0">
                <b-row v-for="product in dataPagination" :key="product.id">
                    <b-col md="12">
                        <div class="card card-pdv">
                            <PageHeader :title="product.name" />
                            <div class="card-body">
                                <b-table-simple
                                    hover
                                    show-empty
                                    sticky-header="900px"
                                >
                                    <b-thead>
                                        <b-tr>
                                            <b-th
                                                sticky-column
                                                style="background-color: #fff;"
                                            >
                                                {{ i18nWeeks }}
                                            </b-th>
                                            <b-th
                                                v-for="chain in product.weeks[0].chains"
                                                :key="chain.id"
                                                class="text-center"
                                                style="background-color: #fff;"
                                            >
                                            {{ chain.name }}
                                            </b-th>
                                        </b-tr>
                                    </b-thead>
                                    <b-tbody>
                                        <b-tr v-for="week in product.weeks" :key="week.id">
                                            <b-td
                                                sticky-column
                                                style="background-color: #fff;"
                                            >
                                                {{ formatWeek(week.week) }}
                                            </b-td>
                                            <b-td
                                                v-for="chain in week.chains"
                                                :key="chain.id"
                                                class="text-center"
                                            >
                                                {{ getI18nCurrency(chain.value) }}
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-if="data.length <= 0 && !loading">
            <no-records-found />
        </div>
        <div v-if="currentPage >= lastPage && disableInfiniteScroll">
            <end-timeline />
        </div>
        <div v-if="loading">
            <b-row v-for="(index) in perPage" :key="index">
                <b-col md="12">
                    <div class="card card-pdv">
                        <PageHeader :title="i18nProduct" />
                        <div class="card-body">
                            <b-skeleton-table
                                :rows="5"
                                :columns="10"
                                :table-props="{
                                    hover: true,
                                    noBorderCollapse: true,
                                }"
                            />
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import PriceService from '@src/services/dashboards/widgets/PriceService.js'

import EndTimeline from '@/src/components/EndTimeline'
import NoRecordsFound from '@/src/components/NoRecordsFound'
import PageHeader from '@/src/components/PageHeader.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatter from '@src/mixins/vuetableFormatters'
import filterVuetable from '@src/mixins/filterVuetable'
import moment from 'moment';
import cloneObject from '@/src/utils/clone-object'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        EndTimeline,
        NoRecordsFound,
        PageHeader,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatter,
        filterVuetable,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loading: false,
            enableInfiniteScroll: false,
            lastPage: 999,
            currentPage: 1,
            perPage: 2,
            totalItems: 0,
            inheritedAdditionalParameters: {},
            additionalParameters: {
                start: moment().startOf('day').add(-30, 'days').format('YYYY-MM-DD'),
                end: moment().startOf('day').format('YYYY-MM-DD'),
            },
            data: [],
            dataPagination: [],
            dataPaginationOld: [],
        }
    },
    validations: {},
    computed: {
        i18nWeeks() {
            return this.getI18nModified({
                        prefix: i18nCommon,
                        suffix: 'week',
                        modifier: 2,
                    })
        },
        i18nProduct() {
            return this.getI18n('PRODUCTS', 'TITLES.product')
        },
        i18nPresent() {
            return this.getI18n(i18nCommon, 'present')
        },
        i18nRupture() {
            return this.getI18n(i18nCommon, 'rupture')
        },
        i18nNotRated() {
            return this.getI18n(i18nCommon, 'not_rated')
        },
        disableInfiniteScroll() {
            return !this.enableInfiniteScroll
        },
    },
    watch: {
        dataPagination: {
            deep: true,
            handler: function (newValue) {
                if (this.dataPaginationOld.length > 0) {
                    for (let i = 0; i < newValue.length; i++) {
                        if (this.dataPaginationOld[i] && newValue[i].currentPage !== this.dataPaginationOld[i].currentPage) {
                            this.dataPagination[i].weeks = this.data[i].weeks
                        }
                    }
                }

                this.dataPaginationOld = cloneObject(this.dataPagination)
            },
        },
    },
    methods: {
        createAdditionalParameters() {
            return this.formatParameters({
                ...this.inheritedAdditionalParameters,
                per_page: this.perPage,
                page: this.currentPage,
                orderBy: 'name',
                sortedBy: "desc"
            })
        },
        filter() {
            this.additionalParameters = this.createAdditionalParameters()
            this.refresh()
        },
        refresh(parameters) {
            if (parameters) {
                this.enableInfiniteScroll = true
                this.lastPage = 999
                this.currentPage = 0
                this.data = []
                this.dataPagination = []
                this.dataPaginationOld = []
                this.inheritedAdditionalParameters = parameters
            }
            this.currentPage += 1

            if (this.currentPage > this.lastPage) {
                this.enableInfiniteScroll = false
                return
            }

            this.additionalParameters = this.createAdditionalParameters()

            this.loading = true

            PriceService.fetchAll(this.additionalParameters, 'per-chain')
                .then((response) => {
                    Array.prototype.push.apply(this.data, response.data.data)

                    response.data.data.forEach(item => {
                      this.dataPagination.push({
                        id: item.id,
                        name: item.name,
                        weeks: item.week,
                        currentPage: 1,
                        total: item.week.length,
                      })
                    });

                    this.lastPage = response.data.meta.last_page
                    this.totalItems = response.data.meta.total
                    this.perPage = response.data.meta.per_page

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
