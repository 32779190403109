<template>
    <form-modal
        ref="formModal"
        :title="title"
        size="lg">
        <template v-slot:form>
            <api-mode-vuetable
                ref="vuetable"
                :fetch-data="fetch"
                :fields="fields"
                :per-page="perPage"
                :additional-parameters="additionalParameters"
                :sort-order="sortOrder">
                <template slot="avg_number" slot-scope="props">
                    <i
                        v-if="props.rowData.avg_number > modal.max_price"
                        class="fe-arrow-up-circle icon-red mr-1"
                    />
                    <i
                        v-else-if="props.rowData.avg_number < modal.min_price"
                        class="fe-arrow-down-circle icon-orange mr-1"
                    />
                    <i
                        v-else
                        class="fe-circle icon-green mr-1"
                    />
                    {{ getI18nCurrency(props.rowData.avg_number) }}
                </template>
            </api-mode-vuetable>
        </template>
        <template v-slot:footer>
            <b-row class="d-flex justify-content-center">
                <div>
                    <i class="fe-circle icon-green mr-1"/>
                    {{ getI18n(i18nKey, 'TYPES.price_within_margin') }}
                </div>
                <div class="ml-3">
                    <i class="fe-arrow-up-circle icon-red mr-1"/>
                    {{ getI18n(i18nKey, 'TYPES.price_above_margin') }}
                </div>
                <div class="ml-3">
                    <i class="fe-arrow-down-circle icon-orange mr-1"/>
                    {{ getI18n(i18nKey, 'TYPES.price_below_margin') }}
                </div>
            </b-row>
        </template>
    </form-modal>
</template>

<script>
import PriceService from '@src/services/dashboards/widgets/PriceService.js'

import FormModal from '@/src/components/FormModal.vue'
import ApiModeVuetable from '@/src/components/ApiModeVuetable.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import dueDateTypes from '@src/mixins/dueDateTypes'
import filterVuetable from '@src/mixins/filterVuetable'
import vuetableFormatter from '@src/mixins/vuetableFormatters'

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        ApiModeVuetable,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        dueDateTypes,
        filterVuetable,
        vuetableFormatter,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            perPage: 10,
            modal: {},
            additionalParameters: {},
            title: '',
            sortOrder: [
                {
                    field: 'date',
                    direction: 'asc',
                },
            ],
            fields: [
                {
                    name: 'date',
                    title: this.getI18n(i18nCommon, 'date'),
                    sortField: 'date',
                    formatter: this.formatDate,
                },
                {
                    name: 'avg_number',
                    title: this.getI18n(i18nKey, 'TITLES.price'),
                    sortField: 'avg_number',
                },
            ],
        }
    },
    validations: {},
    computed: {
        i18nOutPrice() {
            return this.getI18n(i18nKey, 'TITLES.out_price')
        },
    },
    methods: {
        fetch(url, params) {
            return PriceService.fetchVuetable('out-detail', params)
        },
        showModal(data, parameters) {
            this.modal = data
            this.additionalParameters = {
                ...parameters,
                point_of_sale_id: data.pos_id,
            }
            this.title = `${this.i18nOutPrice} (${data.product_name})`;
            this.submitLoading = false
            this.$refs.formModal.show()
        },
    },
}
</script>
