<template>
    <div class="card card-pdv">
        <PageHeader :title="i18nPriceComparison" />
        <div class="card-body">
            <b-table
                hover
                :fields="fields"
                :busy="loading"
                :items="items"
                :current-page="1"
                :per-page="perPage"
            >
                <template v-slot:table-busy>
                    <b-skeleton-table
                    :rows="4"
                    :columns="fields.length"
                    :table-props="{
                        hover: true,
                        noBorderCollapse: true,
                    }"
                    />
                </template>
                <template v-slot:cell(first_week)="row">
                    <b-row class="text-center">
                        {{ getI18nCurrency(row.item.first_week.avg_number) }}
                    </b-row>
                    <b-row>
                        {{ formatPercentageInteger(row.item.first_week.percentage) }}
                    </b-row>
                </template>
                <template v-slot:cell(second_week)="row">
                    <b-row class="text-center">
                        {{ getI18nCurrency(row.item.second_week.avg_number) }}
                    </b-row>
                    <b-row>
                        {{ formatPercentageInteger(row.item.second_week.percentage) }}
                    </b-row>
                </template>
                <template v-slot:cell(third_week)="row">
                    <b-row class="text-center">
                        {{ getI18nCurrency(row.item.third_week.avg_number) }}
                    </b-row>
                    <b-row>
                        {{ formatPercentageInteger(row.item.third_week.percentage) }}
                    </b-row>
                </template>
                <template v-slot:cell(fourth_week)="row">
                    <b-row class="text-center">
                        {{ getI18nCurrency(row.item.fourth_week.avg_number) }}
                    </b-row>
                    <b-row>
                        {{ formatPercentageInteger(row.item.fourth_week.percentage) }}
                    </b-row>
                </template>
                <template v-slot:cell(fifth_week)="row">
                    <b-row class="text-center">
                        {{ getI18nCurrency(row.item.fifth_week.avg_number) }}
                    </b-row>
                    <b-row>
                        {{ formatPercentageInteger(row.item.fifth_week.percentage) }}
                    </b-row>
                </template>
            </b-table>
            <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            align="right"
            variant="danger"
            />
        </div>
    </div>
</template>

<script>
import PriceService from '@src/services/dashboards/widgets/PriceService.js'

import PageHeader from '@/src/components/PageHeader.vue'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import vuetableFormatters from '@src/mixins/vuetableFormatters.vue'

const i18nKey = 'DASHBOARDS'

export default {
    components: {
        PageHeader,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        vuetableFormatters,
    ],
    data() {
        return {
            i18nKey,
            loading: false,
            currentPage: 1,
            totalItems: 0,
            perPage: 10,
            items: [],
            additionalParameters: {},
            fields: [
                {
                    key: 'name',
                    label: this.getI18n('PRODUCTS', 'TITLES.product'),
                },
                {
                    key: 'first_week',
                    label: this.getI18n(i18nKey, 'TYPES.first_week'),
                },
                {
                    key: 'second_week',
                    label: this.getI18n(i18nKey, 'TYPES.second_week'),
                },
                {
                    key: 'third_week',
                    label: this.getI18n(i18nKey, 'TYPES.third_week'),
                },
                {
                    key: 'fourth_week',
                    label: this.getI18n(i18nKey, 'TYPES.fourth_week'),
                },
                {
                    key: 'fifth_week',
                    label: this.getI18n(i18nKey, 'TYPES.fifth_week'),
                },
            ]
        }
    },
    validations: {},
    computed: {
        i18nPriceComparison() {
            return this.getI18n(i18nKey, 'TITLES.price_comparison')
        },
    },
    watch: {
        currentPage(newPage, oldPage) {
            if (newPage !== oldPage) {
                this.refresh()
            }
        },
    },
    methods: {
        refresh(parameters) {
            if (parameters) {
                this.additionalParameters = parameters
            }

            const compareParameters = {
                ...this.additionalParameters,
                per_page: this.perPage,
                page: this.currentPage,
                orderBy: 'id',
                sortedBy: "asc"
            }

            this.loading = true
            PriceService.fetchAll(compareParameters, 'compare')
                .then((response) => {
                    this.items = []

                    const { data } = response.data

                    Object.entries(data).forEach(([key, value]) => {
                        const product = {
                            name: key,
                            first_week: {
                                avg_number : 0,
                                percentage: 0,
                            },
                            second_week: {
                                avg_number : 0,
                                percentage: 0,
                            },
                            third_week: {
                                avg_number : 0,
                                percentage: 0,
                            },
                            fourth_week: {
                                avg_number : 0,
                                percentage: 0,
                            },
                            fifth_week: {
                                avg_number : 0,
                                percentage: 0,
                            },
                        }

                        if (value.find((item) => item.week === 1)) {
                            product.first_week =  value.find((item) => item.week === 1)
                        }

                        if (value.find((item) => item.week === 2)) {
                            product.second_week = value.find((item) => item.week === 2)
                        }

                        if (value.find((item) => item.week === 3)) {
                            product.third_week = value.find((item) => item.week === 3)
                        }

                        if (value.find((item) => item.week === 4)) {
                            product.fourth_week = value.find((item) => item.week === 4)
                        }

                        if (value.find((item) => item.week === 5)) {
                            product.fifth_week = value.find((item) => item.week === 5)
                        }

                        this.items.push(product)
                    })

                    this.totalItems = response.data.meta.total
                    this.perPage = response.data.meta.per_page

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    return err
                })
        },
    },
}
</script>
