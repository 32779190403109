<template>
    <form-modal
        ref="formModal"
        :title="getI18nModified({ prefix: 'COMMON.filter', modifier: 2 })"
        size="lg"
        @handleHide="handleHide">
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nPeriod"
                            label-for="filter-period"
                            class="required label-pdv">
                            <date-range-picker
                                :value.sync="filterModal.date"
                                :max-date="new Date()"
                                :disable-clean-button="true"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <treeSelectWithService
                            ref="productCategoryTreeSelect"
                            v-model="filterModal.category"
                            :async-search="false"
                            :option="defaultCategory"
                            :multiple="false"
                            :value-consists-of="valueConsistsOf"
                            :service="$store.getters['auth/isBusinessUnit'] ? 'product-categories-client' : 'product-categories'"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_category')"
                            :label="i18nProductCategory"
                            :required="true"
                            :select-class="validationClass($v.filterModal.category)"
                            :parameters="parameters"
                        />
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nProduct"
                            label-for="filter-product"
                            class="required label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_product')">
                            <multiSelectWithService
                                :id="'filter-product'"
                                ref="productMultiselect"
                                v-model="filterModal.product"
                                :service="'products'"
                                :searchable="true"
                                :multiple="false"
                                :preselect-first="true"
                                :parameters="filterProductParameters"
                                :disabled="!filterModal.category"
                                :class="validationClass($v.filterModal.product)"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nCompetitor"
                            label-for="filter-competitor"
                            class="label-pdv"
                            :invalid-feedback="getI18n('ERROR_CODES.invalid_competitor')">
                            <multiSelectWithService
                                :id="'filter-competitor'"
                                ref="competitorMultiselect"
                                v-model="filterModal.competitor"
                                :service="'products'"
                                :searchable="true"
                                :multiple="false"
                                :preselect-first="true"
                                :parameters="filterCompetitorParameters"
                                :disabled="!filterModal.category"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nRegional"
                            label-for="filter-regional"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-regional'"
                                ref="regionalMultiselect"
                                v-model="filterModal.regionals"
                                :service="'pos-regionals'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChain"
                            label-for="filter-chain"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-chain'"
                                ref="chainUnitMultiselect"
                                v-model="filterModal.chains"
                                :service="'pos-chains'"
                                :searchable="true"
                                :multiple="true"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="6">
                        <b-form-group
                            :label="i18nChannel"
                            label-for="filter-channel"
                            class="label-pdv">
                            <multiSelectWithService
                                :id="'filter-channel'"
                                ref="channelMultiselect"
                                v-model="filterModal.channels"
                                :service="'pos-channels'"
                                :searchable="true"
                                :multiple="true"
                                :parameters="parameters"
                            />
                        </b-form-group>
                    </b-col>
                </b-row>
            </form>
        </template>
        <template v-slot:footer>
            <save-button
                ref="okButton"
                :busy="submitLoading"
                :disabled="submitLoading"
                :title="getI18n('ACTION.filter')"
                @onClick="handleOk"
            />
            <clear-filter-button
                @onClick="handleClearFilter"
            />
        </template>
    </form-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import dueDateTypes from '@src/mixins/dueDateTypes'
import filterVuetable from '@src/mixins/filterVuetable'
import FormModal from '@/src/components/FormModal.vue'
import SaveButton from '@/src/components/SaveButton'
import ClearFilterButton from '@/src/components/ClearFilterButton'
import cloneObject from '@utils/clone-object'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import treeSelectWithService from '@src/components/treeSelectWithService'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        FormModal,
        SaveButton,
        ClearFilterButton,
        DateRangePicker,
        multiSelectWithService,
        treeSelectWithService,
    },
    mixins: [
        validationMixin,
        swalFeedback,
        dueDateTypes,
        filterVuetable,
    ],
    props: {
        handleSubmit: {
            type: Function,
            required: true,
            default: function () {},
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            filterModal: {},
            submitLoading: false,
            filterProductParameters: {},
            filterCompetitorParameters: {},
            parameters: {
                with_admin: true,
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            defaultCategory: { id: null, name: '', parent_id: null },
            valueConsistsOf: 'LEAF_PRIORITY',
        }
    },
    validations: {
        filterModal: {
            date: {
                required,
            },
            category: {
                required,
            },
            product: {
                required,
            },
        }
    },
    computed: {
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nProduct() {
            return this.getI18n('PRODUCTS', 'TITLES.product')
        },
        i18nCompetitor() {
            return this.getI18n('PRODUCTS', 'FIELDS.competitor')
        },
        i18nRegional() {
            return this.getI18n('POS', 'TITLES.regional')
        },
        i18nChain() {
            return this.getI18n('POS', 'TITLES.chain')
        },
        i18nBusinessUnit() {
            return this.getI18n(i18nCommon, 'business_unit')
        },
        i18nChannel() {
            return this.getI18n('POS', 'TITLES.channel')
        },
        i18nProductCategory() {
            return this.getI18n('PRODUCT_CATEGORIES', 'TITLES.product_category')
        },
    },
    watch: {
        'filterModal.category': function (newValue) {
            this.filterProductParameters = this.formatParameters({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                is_competitor: false,
                product_category_id: newValue ? [newValue] : [0],
            })

            this.$nextTick(() => {
                this.$refs.productMultiselect.loadOptions(this.filterProductParameters)
            })

            this.filterCompetitorParameters = this.formatParameters({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
                is_competitor: true,
                product_category_id: newValue ? [newValue] : [0],
            })

            this.$nextTick(() => {
                this.$refs.competitorMultiselect.loadOptions(this.filterCompetitorParameters)
            })
        },
    },
    methods: {
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },
        showModal(previousFilters) {
            this.filterModal = cloneObject(previousFilters);
            this.submitLoading = false
            this.$refs.formModal.show()
        },
        cleanModal() {
            this.filterModal = Object.assign(this.filterModal, {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                category: null,
                product: null,
                competitor: null,
                regionals: [],
                chains: [],
                channels: [],
            })
            this.resetValidation()
        },
        handleHide() {
            if (!this.submitLoading) {
                this.cleanModal();
            }
        },
        handleOk() {
            this.$v.filterModal.$touch()
            if (!this.$v.filterModal.$invalid && !this.submitLoading) {
                this.submitLoading = true;
                this.$props.handleSubmit(this.filterModal);
                this.$refs.formModal.hide()
            }
        },
        async handleClearFilter() {
            this.cleanModal()
        }
    },
}
</script>
