<template>
    <Layout>
       <div class="col-12">
            <Breadcrumb :items="items" />
            <b-row class="mt-3">
                <b-col md="3">
                    <b-form-group
                        :label="i18nPeriod"
                        class="required label-pdv"
                        label-for="filter-date"
                    >
                        <date-range-picker
                            :value.sync="filters.date"
                            :max-date="new Date()"
                            :disabled="loading"
                            :disable-clean-button="true"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="3">
                    <treeSelectWithService
                        ref="productCategoryTreeSelect"
                        v-model="filters.category"
                        :async-search="false"
                        :option="defaultCategory"
                        :multiple="false"
                        :value-consists-of="valueConsistsOf"
                        :service="$store.getters['auth/isBusinessUnit'] ? 'product-categories-client' : 'product-categories'"
                        :disable-branch-nodes="true"
                        :invalid-feedback="getI18n('ERROR_CODES.invalid_category')"
                        :label="i18nProductCategory"
                        :disabled="loading"
                        :required="true"
                        :select-class="validationClass($v.filters.category)"
                        :parameters="filterParameters"
                    />
                </b-col>
                <b-col v-if="filters.category > 0" md="3">
                    <b-form-group
                        :label="i18nProduct"
                        label-for="filter-product"
                        class="required label-pdv"
                        :invalid-feedback="getI18n('ERROR_CODES.invalid_product')">
                        <multiSelectWithService
                            :id="'filter-product'"
                            ref="productMultiselect"
                            v-model="filters.product"
                            :service="'products'"
                            :searchable="true"
                            :multiple="false"
                            :preselect-first="true"
                            :parameters="filterProductParameters"
                            :disabled="filters.category === null || loading"
                            :class="validationClass($v.filters.product)"
                        />
                    </b-form-group>
                </b-col>
                <b-col v-if="filters.category > 0" md="3">
                    <b-form-group
                        :label="i18nCompetitor"
                        label-for="filter-competitor"
                        class="label-pdv"
                        :invalid-feedback="getI18n('ERROR_CODES.invalid_competitor')">
                        <multiSelectWithService
                            :id="'filter-competitor'"
                            ref="competitorMultiselect"
                            v-model="filters.competitor"
                            :service="'products'"
                            :searchable="true"
                            :multiple="false"
                            :preselect-first="true"
                            :parameters="filterCompetitorParameters"
                            :disabled="filters.category === null || loading"
                        />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row class="d-flex justify-content-center mb-3">
                    <more-filters-label-button class="col-sm-6 col-md-3" :disabled="loading" @onClick="showFilterModal" />
                    <filter-button class="col-sm-6 col-md-3" :disabled="loading" @onClick="filter" />
            </b-row>
            <b-tabs
                v-show="loaded"
                id="price-dashboard-tabs"
                v-model="tabIndex"
                class="tabs-pdv"
                @activate-tab="tabActivated"
            >
                <b-tab :title="i18nAveragePrice">
                    <average-price-tab
                        ref="averagePriceTab"
                        :category-id="filters.category"
                        :product="filters.product ? filters.product : null"
                        :competitor="filters.competitor ? filters.competitor : null"
                    />
                </b-tab>
                <b-tab :title="i18nOutPrice">
                    <out-price-tab ref="outPriceTab" />
                </b-tab>
                <b-tab :title="i18nChainPrice">
                    <chain-price-tab ref="chainPriceTab" />
                </b-tab>
            </b-tabs>
            <div v-show="!loaded">
                <no-filter-applied />
            </div>
       </div>
        <price-filter-modal ref="priceFilterModal" :handle-submit="filter" />
    </Layout>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import Layout from '@layouts/main'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import MoreFiltersLabelButton from '@/src/components/MoreFiltersLabelButton'
import FilterButton from '@/src/components/FilterButton'
import DateRangePicker from '@/src/components/DateRangePicker'
import multiSelectWithService from '@src/components/multiSelectWithService.vue'
import treeSelectWithService from '@src/components/treeSelectWithService'
import averagePriceTab from '@src/router/views/dashboards/price/averagePriceTab.vue'
import outPriceTab from '@src/router/views/dashboards/price/outPriceTab.vue'
import chainPriceTab from '@src/router/views/dashboards/price/chainPriceTab.vue'
import priceFilterModal from '@src/router/views/dashboards/price/priceFilterModal.vue'
import NoFilterApplied from '@src/components/NoFilterApplied.vue'
import filterVuetable from '@src/mixins/filterVuetable'
import validationMixin from '@src/mixins/validation.vue'
import moment from 'moment';

const i18nKey = 'DASHBOARDS'
const i18nCommon = 'COMMON'

export default {
    components: {
        Layout,
        Breadcrumb,
        MoreFiltersLabelButton,
        FilterButton,
        DateRangePicker,
        multiSelectWithService,
        treeSelectWithService,
        averagePriceTab,
        outPriceTab,
        chainPriceTab,
        priceFilterModal,
        NoFilterApplied,
    },
    mixins: [
        filterVuetable,
        validationMixin,
    ],
    data() {
        return {
            i18nKey,
            i18nCommon,
            loaded: false,
            loading: false,
            tabAveragePriceLoaded: false,
            tabOutPriceLoaded: false,
            tabChainPriceLoaded: false,
            tabIndex: 0,
            additionalParameters: {},
            filterParameters: {
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc',
            },
            filterProductParameters: {},
            filterCompetitorParameters: {},
            filters: {
                date: {
                    start: moment().startOf('day').add(-30, 'days').toDate(),
                    end: moment().startOf('day').toDate()
                },
                category: null,
                product: null,
                competitor: null,
                regionals: [],
                chains: [],
                channels: [],
            },
            defaultCategory: { id: null, name: '', parent_id: null },
            valueConsistsOf: 'LEAF_PRIORITY',
        }
    },
    validations: {
        filters: {
            date: {
                required,
            },
            category: {
                required,
            },
            product: {
                required,
            },
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: i18nKey,
                        suffix: 'TITLES.dashboards',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18n(i18nKey, 'TITLES.price_dashboard'),
                    active: true,
                },
            ]
        },
        i18nPeriod() {
            return this.getI18n(i18nCommon, 'period')
        },
        i18nProductCategory() {
            return this.getI18n('PRODUCT_CATEGORIES', 'TITLES.product_category')
        },
        i18nProduct() {
            return this.getI18n('PRODUCTS', 'TITLES.product')
        },
        i18nCompetitor() {
            return this.getI18n('PRODUCTS', 'FIELDS.competitor')
        },
        i18nAveragePrice() {
            return this.getI18n(i18nKey, 'TITLES.average_price')
        },
        i18nOutPrice() {
            return this.getI18n(i18nKey, 'TITLES.out_price')
        },
        i18nChainPrice() {
            return this.getI18n(i18nKey, 'TITLES.chain_price')
        },
    },
    watch: {
        'filters.category': function (newValue) {
            this.filters.product = null
            this.filters.competitor = null

            if (newValue) {
                this.filterProductParameters = this.formatParameters({
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                    is_competitor: false,
                    product_category_id: newValue ? [newValue] : [0],
                })

                this.$nextTick(() => {
                    this.$refs.productMultiselect.loadOptions(this.filterProductParameters)
                })

                this.filterCompetitorParameters = this.formatParameters({
                    per_page: 1000,
                    page: 1,
                    orderBy: 'name',
                    sortedBy: 'asc',
                    is_competitor: true,
                    product_category_id: newValue ? [newValue] : [0],
                })

                this.$nextTick(() => {
                    this.$refs.competitorMultiselect.loadOptions(this.filterCompetitorParameters)
                })
            }
        },
    },
    mounted() {
        this.resetValidation()
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex) {
            if (newTabIndex !== oldTabIndex) {
                this.tabIndex = newTabIndex
                this.refresh()
            }
        },
        createResumeParameters() {
            const period = this.formatFilterDate(this.filters.date)
            return this.formatParameters({
                product_category_id: this.filters.category,
                product_id: this.filters.product ? this.filters.product.id : null,
                competitor_id: this.filters.competitor ? this.filters.competitor.id : null,
                regional_id: this.filters.regionals.map((regional) => regional.id),
                chain_id: this.filters.chains.map((chain) => chain.id),
                channel_id: this.filters.channels.map((channel) => channel.id),
                start: period.from,
                end: period.to,
            })
        },
        filter(appliedFilters) {
            this.$v.filters.$touch()
            if (appliedFilters || (!this.$v.filters.$invalid && !this.loading)) {
                if (appliedFilters) {
                    this.filters = appliedFilters
                }
                this.additionalParameters = this.createResumeParameters()
                this.tabAveragePriceLoaded = false
                this.tabOutPriceLoaded = false
                this.tabChainPriceLoaded = false
                this.refresh()
            }
        },
        showFilterModal() {
            this.$nextTick(() => this.$refs.priceFilterModal.showModal(this.filters))
        },
        refresh() {
            this.loading = true

            if (!this.tabAveragePriceLoaded && this.tabIndex === 0) {
                this.tabAveragePriceLoaded = true
                this.$refs.averagePriceTab.refresh(this.additionalParameters)
            }
            if (!this.tabOutPriceLoaded && this.tabIndex === 1) {
                this.tabOutPriceLoaded = true
                this.$refs.outPriceTab.refresh(this.additionalParameters)
            }
            if (!this.tabChainPriceLoaded && this.tabIndex === 2) {
                this.tabChainPriceLoaded = true
                this.$refs.chainPriceTab.refresh(this.additionalParameters)
            }

            this.loading = false
            this.loaded = true
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
        },
    }
}
</script>
