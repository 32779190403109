import BaseService from '@src/services/BaseService'

class PriceService extends BaseService {
    constructor() {
        super('dashboards/widgets/price')
    }

    resume(params) {
        return this.axios.post(`${this.getUrl()}/resume`, params)
    }

    weekMedium(params) {
        return this.axios.post(`${this.getUrl()}/week-medium`, params)
    }

    mediumCompare(params) {
        return this.axios.post(`${this.getUrl()}/medium-compare`, params)
    }

    minMax(params) {
        return this.axios.post(`${this.getUrl()}/min-max`, params)
    }

    mediumCategory(params) {
        return this.axios.post(`${this.getUrl()}/medium-category`, params)
    }

    mediumProductCompareMediumCategory(params) {
        return this.axios.post(`${this.getUrl()}/medium-product-compare-medium-category`, params)
    }
}

export default new PriceService()

